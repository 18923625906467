export default {
    data() {
        return {
            searchItems: [
                {
                    label: "评价时间",
                    type: "date",
                    dateType: "datetimerange",
                    key: "date",
                    keys: ["evaTimeFrom", "evaTimeTo"],
                    range: true
                }, {
                    label: "订单编号",
                    key: "tranId"
                }, {
                    label: "商品名称",
                    key: "onPluName"
                }, {
                    label: "评价星级",
                    key: "evaScore",
                    type: "select",
                    options: [
                        { label: "一星", value: "1" },
                        { label: "二星", value: "2" },
                        { label: "三星", value: "3" },
                        { label: "四星", value: "4" },
                        { label: "五星", value: "5" }
                    ]
                }
            ],
            tableTabs: [
                { label: "全部评价", value: 1 },
                { label: "已回复", value: 2 },
                { label: "未回复", value: 3 }
            ]
        }
    }
}