<template>
    <div class="page msg-manage-page">
        <Search
            :items="searchItems"
            :data.sync="searchData"
            @search="startSearch"
            @reset="reset"
        />
        <!-- <el-radio-group
            v-model="sta"
            @change="startSearch"
            class="table-tab-container"
        >
            <el-radio-button
                v-for="tab in tableTabs"
                :key="tab.value"
                :label="tab.value"
                >{{ tab.label }}</el-radio-button
            >
        </el-radio-group> -->
        <div class="table-tab-panel">
            <el-radio-group v-model="sta" size="small" @change="startSearch">
                <el-radio-button
                    v-for="tab in tableTabs"
                    :key="tab.value"
                    :label="tab.value"
                >{{ tab.label }}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="msg-table-container">
            <div class="msg-table-head">
                <div style="width: 20%">商品信息</div>
                <div style="width: 50%">评价内容</div>
                <div style="width: 20%">评价星级</div>
                <div style="width: 10%">回复</div>
            </div>
            <div
                v-if="msg.length == 0"
                style="padding: 20px; text-align: center"
                class="empty"
            >
                暂无数据
            </div>
            <div v-else class="msg-table-body" v-loading="loading">
                <el-scrollbar style="height: 100%; overflow-x: hidden">
                    <div
                        class="msg-list-item"
                        v-for="it in msg"
                        :key="it.evaId"
                    >
                        <div class="msg-item-head">
                            <div class="left-head">
                                <p>订单编号: {{ it.tranId }}</p>
                                <p>评价时间: {{ it.createDate }}</p>
                            </div>
                            <div class="right-head">
                                <p>评价人: {{ Base64.decode(it.nickName) }}</p>
                            </div>
                        </div>
                        <div class="msg-item-detail">
                            <div class="plu-info item-item" style="width: 20%">
                                <div class="plu-img small-item">
                                    <p class="img-box">
                                        <img :src="it.iconImg" />
                                    </p>
                                </div>
                                <div class="plu-detail small-item">
                                    <div>
                                        <p>{{ it.onPluName }}</p>
                                        <div
                                            v-html="formatSku(it.onSkuDetail)"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="msg-info item-item" style="width: 50%">
                                <div
                                    class="msg-img small-item"
                                    v-if="it.evaPics"
                                >
                                    <p
                                        class="img-box"
                                        v-for="img in it.evaPics.split(';')"
                                        :key="img"
                                    >
                                        <el-image
                                            :src="img"
                                            :preview-src-list="[img]"
                                        />
                                        <!-- <img :src="img" /> -->
                                    </p>
                                </div>
                                <div class="msg-text small-item">
                                    {{ it.evaComment }}
                                </div>
                            </div>
                            <div class="msg-star item-item" style="width: 20%">
                                <el-rate
                                    v-model="it.descMatchScore"
                                    disabled
                                    text-color="#ff9900"
                                >
                                </el-rate>
                            </div>
                            <div
                                class="msg-status item-item"
                                style="width: 10%"
                            >
                                <div
                                    v-if="!it.replyFlag"
                                    class="reply-btn msg-tag"
                                    @click="openReply(it)"
                                >
                                    回复
                                </div>

                                <div
                                    v-else
                                    class="reply-text"
                                    style="padding: 10px; word-break: break-all"
                                >
                                    {{ it.replyComment }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50]"
                :page-size.sync="pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="total"
                :hide-on-single-page="true"
            ></el-pagination>
        </div>
        <el-dialog
            :visible.sync="replyPanelShow"
            :close-on-click-modal="false"
            title="评价回复"
            @close="closeReply"
        >
            <div class="dialog-panel">
                <el-input
                    v-model="replyMsg"
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 6 }"
                    :maxlength="200"
                    :show-word-limit="true"
                    placeholder="请输入回复内容"
                />
            </div>
            <div slot="footer">
                <el-button type="primary" @click="reply">回复</el-button>
                <el-button type="info" @click="closeReply">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import data from "./data";
import { RESET_SEARCH } from "../../events/eventBus";
import { Base64 } from "../../../../utils/base64.min.js";
export default {
    mixins: [data],
    data() {
        return {
            Base64: Base64,
            searchData: {
                replyFlag: null
            },
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            msg: [],
            sta: 1,
            replyPanelShow: false,
            replyMsg: "",
            replyItem: null,
            loading: false,
            flag: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
            this.$EventBus.$on(RESET_SEARCH, () => {
                this.searchData = {};
                this.sta = 1;
                this.startSearch();
            });
        });
    },
    methods: {
        getData() {
            this.loading = true;
            if (this.searchData.date && !this.searchData.date[0]) {
                delete this.searchData.date;
            }
            this.$post("/shop/api/evaluation/list", {
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                ...this.searchData
            }).then(data => {
                this.msg = data.datas;
                this.total = data.recordCount;
                this.loading = false;
            });
        },
        startSearch() {
            switch (this.sta) {
                case 1:
                    this.searchData.replyFlag = null;
                    break;
                case 2:
                    this.searchData.replyFlag = true;
                    break;
                case 3:
                    this.searchData.replyFlag = false;
                    break;
                default:
                    break;
            }
            this.pageIndex = 1;
            this.getData();
        },
        formatSku(sku) {
            let skuObj = JSON.parse(sku),
                tpl = ``;
            Object.keys(skuObj).map(it => {
                tpl += `<p>${it}: ${skuObj[it]}</p>`;
            });
            return tpl;
        },
        // search() {
        //     this.pageIndex = 1;
        //     this.getData();
        // },
        reset() {},
        openReply(item) {
            this.replyItem = item;
            this.replyPanelShow = true;
        },
        closeReply() {
            this.replyItem = null;
            this.replyMsg = "";
            this.replyPanelShow = false;
        },
        reply() {
            if (this.flag) return;
            if (!this.replyMsg.trim()) {
                this.$message.error("请输入回复内容");
                return;
            }
            this.flag = true;
            this.$post("/shop/api/evaluation/reply", {
                replyComment: this.replyMsg,
                evaId: this.replyItem.evaId
            })
                .then(() => {
                    this.getData();
                    this.$message.success("评价成功");
                    this.closeReply();
                    this.flag = false;
                })
                .catch(() => {
                    this.flag = false;
                });
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.getData();
        },
        handleCurrentChange(curr) {
            this.pageIndex = curr;
            this.getData();
        }
    }
};
</script>

<style lang="scss">
.page.msg-manage-page {
    display: flex;
    flex-direction: column;
    .table-tab-container {
        background-color: #fff;
        padding-left: 20px;
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            box-shadow: none;
        }
        .el-radio-button {
            .el-radio-button__inner {
                background: #eee;
                border-bottom: none;
            }

            &.is-active {
                .el-radio-button__inner {
                    background: #fff;
                    border: 1px solid #ccc;
                    border-bottom: none;
                    color: #000;
                }
            }
        }
    }
    .msg-table-container {
        background-color: #fff;
        padding: 0 20px 20px;
        flex: 1;
        overflow: hidden;
        .msg-table-head {
            text-align: center;
            display: flex;
            background: #f8f8f8;
            font-size: 12px;
            height: 50px;
            line-height: 50px;
        }
        .msg-table-body {
            font-size: 14px;
            overflow: auto;
            height: calc(100% - 30px);
            .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .msg-list-item {
                + .msg-list-item {
                    margin-top: 20px;
                }
                &:hover {
                    background: #f5f7fa;
                }
                .msg-item-head {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    background-color: #eee;
                    padding: 10px;
                    > div {
                        display: flex;
                    }
                    .left-head p {
                        margin-right: 20px;
                    }
                    .right-head p {
                        margin-right: 30px;
                        &.reply-btn {
                            color: #2d8cf0;
                            cursor: pointer;
                        }
                    }
                }
                .msg-item-detail {
                    display: flex;
                    border: 1px solid #efefef;
                    .item-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        & + .item-item {
                            border-left: 1px solid #efefef;
                        }
                        .small-item {
                            padding: 10px;
                            flex: 1;
                            display: flex;
                            width: 0;
                            word-break: break-all;
                            justify-content: space-around;
                            & + .small-item {
                                border-left: 1px solid #efefef;
                            }
                        }
                        .img-box {
                            width: 80px;
                            height: 80px;
                            .el-image,
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .msg-tag {
                            cursor: pointer;
                            padding: 5px 15px;
                            border-radius: 20px;
                            color: #fff;
                            &.reply-btn {
                                background: #2d8cf0;
                            }
                            &.sta-1 {
                                background: #f47920;
                            }
                            &.sta-5 {
                                background: #1d953f;
                            }
                            &.sta-9 {
                                background: #918597;
                            }
                        }
                    }
                }
            }
        }
    }
    .pagination {
        background: #fff;
        .el-pagination {
            padding: 10px 20px;
        }
    }
    .el-dialog {
        padding: 15px;
        .el-dialog__header {
            padding: 0 0 15px;
            // background: #f5f5f5;
            // border: 1px solid #ddd;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .el-dialog__headerbtn {
                top: 28px;
                right: 30px;
            }
            .el-dialog__title {
                color: #333;
                font-size: 20px;
                line-height: 28px;
            }
        }
        .el-dialog__body {
            padding: 0;
        }
        .el-dialog__footer {
            padding: 30px 0 0;
        }
    }
}
</style>